//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CountryModel } from '@/modules/country/country-model';
import i18n  from '@/vueI18n';
// import firebase from 'firebase/app'
// import 'firebase/firestore'

const { fields } = CountryModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
]);

export default {
  name: 'app-country-form-modal',

  props: ['id', 'visible'],

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.name = {
        en: undefined,
        ar: undefined,
      }
    }
  },
  data() {
    return {
      successDialog: false,
      model: null,
      rules: formSchema.rules(),
    };
  },
  computed: {
    ...mapGetters({
      record: 'country/form/record',
      findLoading: 'country/form/findLoading',
      saveLoading: 'country/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
    isEditing() {
      return !!this.id;
    },
    language(){
      return i18n.locale
    }
  },
  methods: {
    ...mapActions({
      doFind: 'country/form/doFind',
      doNew: 'country/form/doNew',
      doUpdate: 'country/form/doUpdate',
      doCreate: 'country/form/doCreate',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model);
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values,
        });
        this.$emit('close');
      } else {
        const isCreated = await this.doCreate(values);
        // await this.doFetch()
        this.$emit('close');
        if (isCreated) {
          this.successDialog = true
        }
      }
    },
  },
};
