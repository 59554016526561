//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CountryListTable from '@/modules/country/components/country-list-table.vue';
import CountryFormModal from '@/modules/country/components/country-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { CountryPermissions } from '@/modules/country/country-permissions';
import firebase from 'firebase/app';
import 'firebase/firestore'
import i18n from '@/vueI18n'

export default {
  name: 'app-country-list-page',

  components: {
    [CountryListTable.name]: CountryListTable,
    [CountryFormModal.name]: CountryFormModal,
  },
  data() {
    return {
      modalVisible: false,
      dialogVisible: false,
      unsubscribe: undefined
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new CountryPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
      document.title = this.i18n('app.title') + ' | ' + this.i18n('menu.country') 
    },
  },

  // async created() {
  //   await firebase.firestore().collection('country').onSnapshot(async querySnapshot => {
  //      console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     this.doFilterInBackground();
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  // },
  async mounted() {
    await this.doFetch()
    this.unsubscribe = firebase.firestore().collection('country').onSnapshot(() => {
      this.doFetch()
    })
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  methods: {
    ...mapActions({
      doFetch: 'country/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 250);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  },
};
