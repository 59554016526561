//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n  from '@/vueI18n';
import { CountryPermissions } from '@/modules/country/country-permissions';
import CountryFormModal from '@/modules/country/components/country-form-modal.vue';

// import { CountryModel } from '@/modules/country/country-model';
// const { fields } = CountryModel;

export default {
  name: 'app-country-list-table',

  components: {
    [CountryFormModal.name]: CountryFormModal
  },
  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter:'',
      columns: [
        {
          name: 'id',
          field: 'id',
          label: 'entities.country.fields.id',
          align: 'center',
          required: true,
        },
        {
          name: 'name',
          field: 'name',
          label: 'entities.country.fields.name',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center',
        },
      ],
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: this.$q.screen.width > 599 ? 20 : 10,
        // rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  computed: {
    ...mapGetters({
      rows: 'country/list/rows',
      count: 'country/list/count',
      // pagination: 'country/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'country/list/loading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      // destroyLoading: 'country/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    hasPermissionToEdit() {
      return new CountryPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new CountryPermissions(this.currentUser).destroy;
    },
    // fields() {
    //   return fields;
    // },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    language(){
      return i18n.locale
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'country/list/doChangeSort',
      doChangePaginationCurrentPage: 'country/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'country/list/doChangePaginationPageSize',
      doMountTable: 'country/list/doMountTable',
      // doDestroy: 'country/destroy/doDestroy',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    // presenter(row, fieldName) {
    //   return CountryModel.presenter(row, fieldName);
    // },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'view':
          this.dialogType = 'view'
          break;
      }
      this.dialogVisible = true
    },
    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
  },
};
